<template>
  <div class="mt-5">
    <Overlay :overlay="overlay" />
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-10"><h3>Locations</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="6" sm="6" lg="6" xl="6">
        <v-toolbar dense elevation="0">
          <v-spacer v-if="view_mode == 'map_view'" />
          <v-select
            dense
            outlined
            label="Filter By"
            v-model="organisation_credits"
            style="max-width: 200px"
            :items="organisation_status_items"
            class="mt-5 mr-2"
          ></v-select>
          <v-select
            v-model="view_mode"
            label="Change View"
            class="mt-5 mr-2"
            style="max-width: 200px"
            :items="view_mode_items"
            dense
            outlined
          ></v-select>
          <v-text-field
            v-if="view_mode == 'table_view'"
            v-model="search"
            label="search"
            class="mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="mx-4" v-if="view_mode == 'table_view'" no-gutters>
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="locationData"
          :items="locationItems"
          dense
          :search="search"
          :height="height"
          class="overflow-y-auto"
          :items-per-page="10"
          :fixed-header="fixed"
        >
          <template v-slot:[`item.location_created_on`]="{ item }">
            <span>{{ get_date(item.location_created_on) }}</span>
          </template>

          <template v-slot:[`item.geo`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="view_geo(item)"
                  small
                  v-on="on"
                  :color="
                    item.geo_lat != undefined && item.geo_long != undefined
                      ? 'primary'
                      : ''
                  "
                  v-text="
                    item.geo_lat != undefined && item.geo_long != undefined
                      ? 'mdi-map-marker-radius'
                      : 'mdi-map-marker-off'
                  "
                ></v-icon>
              </template>
              <span class="white--text">{{
                item.geo_lat != undefined && item.geo_long != undefined
                  ? "View Geo"
                  : "Geo Not Enabled"
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.no_of_source`]="{ item }">
            <span v-if="item.no_of_source != undefined">{{
              item.no_of_source
            }}</span>
            <span v-else>-</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mx-4" v-if="view_mode == 'map_view'" no-gutters>
      <v-col cols="12" md="12">
        <GmapMap
          :center="
            swipeData[0] != undefined ? swipeData[0].position : defaultCenter
          "
          :zoom="4"
          map-type-id="terrain"
          style="width: 100%; height: 600px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in swipeData"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :title="
              'Location Name : ' +
              m.location_name +
              ' Of Organisation : ' +
              m.organisation_name
            "
            @click="center = m.position"
          />
        </GmapMap>
      </v-col>
    </v-row>
    <ViewGeo
      @close_dialog="viewGeoDialog = false"
      :viewGeoDialog="viewGeoDialog"
      :geoObject="geoObject"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllLocationsGlobaly } from "@/graphql/queries.js";
import ViewGeo from "@/components/Dialogs/Organisations/ViewGeo.vue";

export default {
  components: {
    Overlay,
    ViewGeo,
  },
  data: () => ({
    height: 0,
    view_mode: "table_view",
    fixed: true,
    search: "",
    locationItems: [],
    locationData: [
      { text: "Organisation Name", value: "organisation_name" },
      { text: "Geo", value: "geo" },
      { text: "Location Name", value: "location_name" },
      { text: "#Sources", value: "no_of_source" },
      { text: "Created On", value: "location_created_on" },
      { text: "Created By", value: "location_created_by" },
    ],
    view_mode_items: [
      { text: "Table View", value: "table_view" },
      { text: "Map View", value: "map_view" },
    ],
    overlay: false,
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    geoObject: {},
    viewGeoDialog: false,
    swipeData: [],
    LocationsList: [],
    organisation_credits: "CUSTOMER",
    organisation_status_items: [
      // { text: "ALL", value: "ALL" },
      { text: "CUSTOMERS", value: "CUSTOMER" },
      { text: "PROSPECTS", value: "PROSPECT" },
      { text: "ACTIVATED", value: "ACTIVATED" },
      { text: "ONBOARDED", value: "ONBOARD" },
    ],
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_locations_list(this.organisation_credits);
  },
  watch: {
    organisation_credits(val) {
      this.count = 0;
      // console.log("organisation_credits", val);
      this.get_locations_list(val);
    },
  },
  methods: {
    view_geo(item) {
      this.geoObject = item;
      this.viewGeoDialog = true;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_locations_list(organisation_credits) {
      this.locationItems = [];
      this.LocationsList = [];
      this.swipeData = [];
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllLocationsGlobaly, {
            input: {
              organisation_type: organisation_credits,
            },
          })
        );
        this.locationItems = JSON.parse(result.data.GetAllLocationsGlobaly);
        this.LocationsList = JSON.parse(result.data.GetAllLocationsGlobaly);
        // console.log("Location", this.locationItems);
        for (let i = 0; i < this.LocationsList.length; i++) {
          this.swipeData.push({
            organisation_name: this.LocationsList[i].organisation_name,
            location_name: this.LocationsList[i].location_name,
            position: {
              lat: Number(this.LocationsList[i].geo_lat),
              lng: Number(this.LocationsList[i].geo_long),
            },
          });
        }
        this.overlay = false;
        this.$forceUpdate();
        // this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
