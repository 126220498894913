var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-10"},[_c('h3',[_vm._v("Locations")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","lg":"6","xl":"6"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[(_vm.view_mode == 'map_view')?_c('v-spacer'):_vm._e(),_c('v-select',{staticClass:"mt-5 mr-2",staticStyle:{"max-width":"200px"},attrs:{"dense":"","outlined":"","label":"Filter By","items":_vm.organisation_status_items},model:{value:(_vm.organisation_credits),callback:function ($$v) {_vm.organisation_credits=$$v},expression:"organisation_credits"}}),_c('v-select',{staticClass:"mt-5 mr-2",staticStyle:{"max-width":"200px"},attrs:{"label":"Change View","items":_vm.view_mode_items,"dense":"","outlined":""},model:{value:(_vm.view_mode),callback:function ($$v) {_vm.view_mode=$$v},expression:"view_mode"}}),(_vm.view_mode == 'table_view')?_c('v-text-field',{staticClass:"mt-5",attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1),(_vm.view_mode == 'table_view')?_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow-y-auto",attrs:{"headers":_vm.locationData,"items":_vm.locationItems,"dense":"","search":_vm.search,"height":_vm.height,"items-per-page":10,"fixed-header":_vm.fixed},scopedSlots:_vm._u([{key:"item.location_created_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.location_created_on)))])]}},{key:"item.geo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.geo_lat != undefined && item.geo_long != undefined
                    ? 'primary'
                    : ''},domProps:{"textContent":_vm._s(
                  item.geo_lat != undefined && item.geo_long != undefined
                    ? 'mdi-map-marker-radius'
                    : 'mdi-map-marker-off'
                )},on:{"click":function($event){return _vm.view_geo(item)}}},on))]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.geo_lat != undefined && item.geo_long != undefined ? "View Geo" : "Geo Not Enabled"))])])]}},{key:"item.no_of_source",fn:function(ref){
                var item = ref.item;
return [(item.no_of_source != undefined)?_c('span',[_vm._v(_vm._s(item.no_of_source))]):_c('span',[_vm._v("-")])]}}],null,true)})],1)],1):_vm._e(),(_vm.view_mode == 'map_view')?_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.swipeData[0] != undefined ? _vm.swipeData[0].position : _vm.defaultCenter,"zoom":4,"map-type-id":"terrain"}},_vm._l((_vm.swipeData),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false,"title":'Location Name : ' +
            m.location_name +
            ' Of Organisation : ' +
            m.organisation_name},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)],1):_vm._e(),_c('ViewGeo',{attrs:{"viewGeoDialog":_vm.viewGeoDialog,"geoObject":_vm.geoObject},on:{"close_dialog":function($event){_vm.viewGeoDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }